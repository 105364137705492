import Link from 'next/link'
import css from './styles.module.scss'
import {FC} from 'react'
import {Button, ContextMenu, Icon} from '@doctena-org/ui-components/src'
import {Header as UiHeader} from '@doctena-org/ui-components/src'
import {useApp, useAuth} from '@/store'
import {useRouter} from 'next/router'
import {useLingui} from '@lingui/react'

const {Item} = ContextMenu

export const Header: FC = () => {
  const {i18n} = useLingui()
  const router = useRouter()
  const {isLogged, logout} = useAuth()
  const {userType} = useApp()
  const {pathname, query} = router
  const href = {pathname, query}
  const isAdmin = userType === `ADMIN`

  return (
    <UiHeader className={css.header}>
      <div className={css.content}>
        <Link href='/' aria-label={i18n._(`Home page`)}>
          <Icon glyph='doctena-logo' height='32px' width='135px'/>
        </Link>
        <div data-testid='header-lang-menu' className={css.menu}>
          <ContextMenu isAutoClose listProps={{position: `bottom-left`, index: undefined}}>
            <Button className={css.button} icon='world' isRounded/>
            <Item data-testid='lang-menu-en' isSelected={router.locale === `en`}>
              <Link locale='en' href={href} className={css.link}>English</Link>
            </Item>
            <Item data-testid='lang-menu-de' isSelected={router.locale === `de`}>
              <Link locale='de' href={href} className={css.link}>Deutsch</Link>
            </Item>
            <Item data-testid='lang-menu-fr' isSelected={router.locale === `fr`}>
              <Link locale='fr' href={href} className={css.link}>Français</Link>
            </Item>
            <Item data-testid='lang-menu-nl' isSelected={router.locale === `nl`}>
              <Link locale='nl' href={href} className={css.link}>Nederlands</Link>
            </Item>
          </ContextMenu>
          {isLogged ? (
            <ContextMenu listProps={{position: `bottom-left`}}>
              <Button
                data-testid='user-menu'
                className={css.button}
                icon='user-alt'
                isRounded
              />
              {isAdmin && (
                <Item data-testid='menu-item-accounts'>
                  <Link href='/accounts' className={css.link}>{i18n._(`Accounts`)}</Link>
                </Item>
              ) as any}
              {!isAdmin && (
                <Item data-testid='menu-item-appointments'>
                  <Link href='/appointments' className={css.link}>{i18n._(`Appointments`)}</Link>
                </Item>
              )}
              <Item
                data-testid='menu-item-logout'
                className='text-coral-100'
                onClick={logout}>
                {i18n._(`Logout`)}
              </Item>
            </ContextMenu>
          ) : null}
        </div>
      </div>
    </UiHeader>
  )
}
