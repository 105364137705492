import Content from '@/contents/login'
import {FC, Fragment} from 'react'
import {Header, Footer} from '@/components'

const Login: FC = () => (
  <Fragment>
    <Header/>
    <Content/>
    <Footer/>
  </Fragment>
)

export default Login
