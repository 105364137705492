import * as yup from 'yup'
import Link from 'next/link'
import css from './styles.module.scss'
import {Button, Card, FormInput} from '@doctena-org/ui-components/src'
import {Checkbox, H3, InfoMessage} from '@doctena-org/ui-components/src'
import {setErrors} from '@/common'
import {useAuth, LoginForm, useApp} from '@/store'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useLingui} from '@lingui/react'
import {t} from '@lingui/macro'

const getValidationSchema = (i18n) => yup.object().shape({
  username: yup
    .string()
    .required(i18n._(`Required field`))
    .matches(new RegExp(`^[\\w-\\.+]+@([\\w-]+\\.)+[\\w-]{2,4}$`), i18n._(`Invalid email address`)),
  password: yup
    .string()
    .required(i18n._(`Required field`)),
})

const Login = () => {
  const {i18n} = useLingui()
  const schema = getValidationSchema(i18n).required()
  const {errors: apiErrors, isLoading, login, set} = useAuth()
  const {formState: {errors}, handleSubmit, register, setError} = useForm<LoginForm>({
    resolver: yupResolver(schema),
  })

  useEffect(() => () => {
    setTimeout(() => set({'errors': null}), 10) // wired errors, `setErrors` looks like overlapping
  }, [])

  setErrors(apiErrors, setError)

  return (
    <main className={css.main}>
      <Card className={css.card}>
        <H3 className={css.title}>
          {t({id: `Login for practitioners`})}
        </H3>
        <hr/>
        <form
          data-testid='login-form'
          autoComplete='off'
          className={css.form}
          onSubmit={handleSubmit(login)}>
          <FormInput
            testId='username'
            autoComplete='new-password'
            error={errors?.username?.message}
            icon='email'
            label={i18n._(`Email`)}
            placeholder={i18n._(`Email`)}
            {...register(`username`)}

          />
          <FormInput
            testId='password'
            autoComplete='new-password'
            icon='password'
            label={i18n._(`Password`)}
            type='password'
            {...register(`password`)}
          />
          <Checkbox
            testId='remember-me'
            className='justify-self-start'
            {...register(`rememberMe`)}>
            {i18n._(`Keep logged in`)}
          </Checkbox>
          <Link
            href='/password/recovery/'
            className={css.link}
            data-testid='recover-password'>
            {i18n._(`Forgot your password?`)}
          </Link>
          <div className={css.oneRow}>
            <InfoMessage
              data-testid='form-info-message'
              type='error'
              isVisible={Boolean(apiErrors?.message)}
              text={apiErrors?.message}
            />
            <Button
              data-testid='button-submit'
              color='tertiary'
              disabled={isLoading}
              icon={isLoading ? `spinner` : `arrow-down`}
              iconPosition='right'
              iconRotation={isLoading ? Infinity : 270}
              type='submit'>
              {i18n._(`Continue`)}
            </Button>
          </div>
        </form>
      </Card>
    </main>
  )
}

export default Login
