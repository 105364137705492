import Link from 'next/link'
import css from './styles.module.scss'
import {getTermsUrl} from '@/common'
import {useApp} from '@/store'
import {useLingui} from '@lingui/react'

export const Footer = () => {
  const {i18n} = useLingui()
  const {locale, userType} = useApp()

  return (
    <footer className={css.footer}>
      <Link href='/static/privacy-policy/' data-testid='privacy-policy'>{i18n._(`Privacy Policy`)}</Link>
      <Link href={getTermsUrl(locale, userType)} data-testid='terms-conditions'>{i18n._(`Terms and Conditions`)}</Link>
      <Link href={`/${locale}/static/impressum/`} data-testid='impressum'>Impressum</Link>
    </footer>
  )
}
